import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [showDot, setShowDot] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowDot(show => !show);
    }, 500); // Change the dot state every 500ms

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to My Terminal</h1>
      </header>
      <div className="terminal-window">
        <p>
          Click Enter to continue
          {showDot ? <span className="blinking-dot">.</span> : ""}
        </p>
      </div>
    </div>
  );
}

export default App;
